import { AdviserService } from './adviser.service';
import { Household } from '../entities/household';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Journey } from '../entities/journey';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HouseholdService {

  constructor(
    private http: HttpClient,
    private adviserService: AdviserService
  ) { }

  household: Household | null | undefined;

  getHousehold(clientID: string): Observable<Household> {
    return this.http.get<Household>(`/api/households/${clientID}`);
  }

  getAttendees(): string {
    if (!this.household) return "";

    return `${this.household.client.firstName} ${this.household.client.lastName}(Primary) ${(this.household.spouse ? `, ${this.household.spouse.firstName} ${this.household.spouse.lastName}(Spouse) ` : "")}and ${this.adviserService.getLoggedInUserName()}`;
  }

  saveHousehold(household: Household, journey: Journey): Observable<Household> {
    const journeyCreateDateString = new Date(journey.created).toISOString();
    // We pass this journey information because we need to validate that the journey is valid.
    return this.http.patch<Household>(`/api/households/${journey.journeyID}/${journeyCreateDateString}/${journey.journeyInstanceID}`, household);
  }
}
