import { Observable, tap } from 'rxjs';
import { AdviserInfo } from '../entities/adviser-info';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdviserService {
  constructor(
    private http: HttpClient
  ) { }

  loggedInUser?: AdviserInfo;

  getLoggedInUser(): Observable<AdviserInfo> {
    return this.http.get<AdviserInfo>("/api/advisers/");
  }

  loadLoggedInUser() {
    return this.http.get<AdviserInfo>(`/api/advisers/`)
      .pipe(
        tap(x => { this.loggedInUser = x })
      );
  }

  getNonNullableLoggedInUser(): AdviserInfo {
    if (!this.loggedInUser) {
      throw new Error("The loggedInUser has not been initialised.");
    }

    return this.loggedInUser;
  }

  getLoggedInUserName(): string {
    return `${this.getNonNullableLoggedInUser().firstName} ${this.getNonNullableLoggedInUser().lastName}`;
  }
}
