import { Observable, firstValueFrom, map } from 'rxjs';
import { AdviserService } from './adviser.service';
import { HouseholdService } from './household.service';
import { HttpClient } from '@angular/common/http';
import { ImportantJourneyDates } from '../entities/important-journey-dates';
import { Injectable } from '@angular/core';
import { Journey } from '../entities/journey';
import { JourneyStatuses } from '../enums/journey-status';
import { SessionTypes } from '../enums/session-type';
import { convertStringToNullableDate } from '../shared/util';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class JourneyService {

  constructor(
    private http: HttpClient,
    private householdService: HouseholdService,
    private adviserService: AdviserService
  ) { }

  selectedSessionType = SessionTypes.None;
  previousJourney: Journey | undefined | null;
  journey: Journey | undefined;

  getJourney(journeyID: string): Observable<Journey | null> {
    return this.http.get<Journey | null>(`/api/journeys/${journeyID}/latest`);
  }

  saveJourney(journey: Journey): Observable<Journey> {
    return this.http.post<Journey>("/api/journeys/", journey);
  }

  GetImportantDates(): Observable<ImportantJourneyDates> {
    const journeyId = this.getNonNullableJourney().journeyID;
    return this.http.get<ImportantJourneyDates>(`/api/journeys/GetImportantJourneyDates/${journeyId}`)
      .pipe(map(response => ({
        lastContinueMeetingDateTime: convertStringToNullableDate(response.lastContinueMeetingDateTime),
        lastJourneyPublishedDateTime: convertStringToNullableDate(response.lastJourneyPublishedDateTime)
      })));
  }

  GetLatestValidJourney(journeyID: string): Observable<Journey | null> {
    return this.http.get<Journey | null>(`/api/journeys/${journeyID}/latestValid`);
  }

  getNonNullableJourney(): Journey {
    if (!this.journey?.journeyID) {
      throw new Error("The journey has not been initialised.");
    }

    return this.journey;
  }

  createJourney(journeyID: string, status: string, sessionType: string, journeyInstanceID: string, successfullySavedToAOS: boolean, refreshedFromAOS: boolean,
    publishedDateTime: Date | null, journeyDataSuccessfullyRefreshed: boolean): Journey {
    const now = moment().utc().toDate();
    return {
      journeyID,
      status,
      sessionType,
      created: now,
      lastModified: now,
      statusUpdateDate: now,
      userNode: this.adviserService.getNonNullableLoggedInUser().node,
      attendees: this.householdService.getAttendees(),
      journeyInstanceID,
      successfullySavedToAOS,
      refreshedFromAOS,
      publishedDateTime,
      journeyDataSuccessfullyRefreshed
    };
  }

  getPreviousJourneyStatus(): string | null {
    if (!this.previousJourney) return null;

    return this.previousJourney.status === JourneyStatuses.Published.toString() ? JourneyStatuses.Published.toString() : JourneyStatuses.Continued.toString();
  }

  async updateRefreshedFromAOS(value: boolean): Promise<void> {
    this.getNonNullableJourney().refreshedFromAOS = value;
    this.getNonNullableJourney().lastModified = moment().utc().toDate();
    await firstValueFrom(this.saveJourney(this.getNonNullableJourney()));
  }

  async updateSuccessfullySavedToAOS(value: boolean): Promise<void> {
    this.getNonNullableJourney().successfullySavedToAOS = value;
    this.getNonNullableJourney().lastModified = moment().utc().toDate();
    await firstValueFrom(this.saveJourney(this.getNonNullableJourney()));
  }

  async updatePublished(): Promise<void> {
    const now = moment().utc().toDate();
    this.getNonNullableJourney().publishedDateTime = now;
    this.getNonNullableJourney().lastModified = now;
    this.getNonNullableJourney().status = JourneyStatuses.Published.toString();
    await firstValueFrom(this.saveJourney(this.getNonNullableJourney()));
  }

  async updateJourneyDataSuccessfullyRefreshed(value: boolean): Promise<void> {
    const now = moment().utc().toDate();
    this.getNonNullableJourney().lastModified = now;
    this.getNonNullableJourney().journeyDataSuccessfullyRefreshed = value;
    await firstValueFrom(this.saveJourney(this.getNonNullableJourney()));
  }
}